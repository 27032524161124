'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var storage = {
  setItem: function setItem(key, value) {
    if (window.localStorage) {
      try {
        window.localStorage.setItem(key, value);
      } catch (e) {
        //f.log('failed to set value for key '' + key + '' to localStorage.');
      }
    }
  },
  getItem: function getItem(key, value) {
    if (window.localStorage) {
      try {
        return window.localStorage.getItem(key, value);
      } catch (e) {
        //f.log('failed to get value for key '' + key + '' from localStorage.');
        return undefined;
      }
    }
  }
};

exports.default = {
  name: 'localStorage',

  lookup: function lookup(options) {
    var found = void 0;

    if (options.lookupLocalStorage && typeof window !== 'undefined' && window.localStorage) {
      var lng = storage.getItem(options.lookupLocalStorage);
      if (lng) found = lng;
    }

    return found;
  },
  cacheUserLanguage: function cacheUserLanguage(lng, options) {
    if (options.lookupLocalStorage && typeof window !== 'undefined' && window.localStorage) {
      storage.setItem(options.lookupLocalStorage, lng);
    }
  }
};